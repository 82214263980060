import { from, InMemoryCache } from '@apollo/client/core';
import { createApolloDocumentTransform } from './apolloDocumentTransform';
import { getGraphQLServerUrl } from '../graphqlServerUrl';
import { Stack } from '../../enums';
import getLocalResolversKeysFromCustomResolvers from '../../utils/resolvers/getLocalResolversKeysFromCustomResolvers';
import { CustomResolvers } from '../../types';
import {
  DebugLink,
  AuthLink,
  OfflineDeviceLink,
  EndpointLink
} from '../../links';

type CreateApolloClientBaseConfigParams = {
  stack: Stack;
  authTokenCallback: () => Promise<string>;
  isShellPortal: boolean;
  serverUrl?: string;
  customResolvers?: CustomResolvers;
};

const createApolloClientBaseConfig = ({
  stack,
  authTokenCallback,
  isShellPortal,
  serverUrl,
  customResolvers
}: CreateApolloClientBaseConfigParams) => {
  const cache = new InMemoryCache();

  /**
   * URL base
   *  */
  const urlBase = serverUrl || getGraphQLServerUrl(stack, isShellPortal);

  /**
   * Link creation
   */
  const authLink = AuthLink(authTokenCallback);

  const offlineDeviceLink = new OfflineDeviceLink();

  const debugLink = new DebugLink(console.debug);

  const endpointLink = EndpointLink(urlBase);

  const finalChainLink = from([
    debugLink,
    authLink,
    offlineDeviceLink,
    endpointLink
  ]);

  // Custom resolvers keys
  const customResolversKeys =
    getLocalResolversKeysFromCustomResolvers(customResolvers);

  /**  The createApolloDocumentTransform function is used to create a document
   * transform function that removes certain fields from the document based on
   * the operation type.
   */
  const documentTransform = createApolloDocumentTransform(customResolversKeys);

  /*
  Here's a brief explanation of how Apollo GraphQL works in this code:

  1. The first step in the process is the Transformers. These functions modify
    the GraphQL document BEFORE sending it to the Apollo Links.

  2. Once the query is transformed, it is passed through a series of Apollo Links.
     Apollo Links are a chain of functions that can manipulate requests and responses
     before they are sent or after they are received. These links can do things like
     adding authentication headers, logging debug information, handling offline
     scenarios, and specifying the endpoint to send the request to.

  3. Finally, the transformed and manipulated request is sent to the server using the
     EndpointLink. The HTTPLink uses the fetch to send the request to cloud server.

  4. After receiving the response, the Apollo pass for each Apollo Link before
    updating the cache with the response data.

*/

  const apolloClientConfig = {
    cache,
    link: finalChainLink,
    documentTransform
  };

  return apolloClientConfig;
};

export default createApolloClientBaseConfig;
