/* eslint-disable @typescript-eslint/no-explicit-any */

/**
 * This method merges two objects, the strongData and the weakData. The strongData has the priority over the weakData.
 * All of the data will be merged into the updatedData. The updated data could be an empty object or the same reference of the strongData or weakData.
 */

const mergeObjects = (
  strongData: Record<string, any>,
  weakData: Record<string, any>,
  updateData: Record<string, any>
) => {
  const strongDataKeys = strongData ? Object.keys(strongData) : [];
  const weakDataKeys = weakData ? Object.keys(weakData) : [];
  const mergedKeys = Array.from(new Set([...strongDataKeys, ...weakDataKeys]));

  mergedKeys.forEach((key) => {
    if (key in strongData) {
      // TODO: Test with array values
      if (typeof updateData[key] === 'object' && updateData[key] !== null) {
        if (typeof strongData[key] === 'object' && strongData[key] !== null) {
          mergeObjects(strongData[key], weakData[key], updateData[key]);
        } else if (
          typeof weakData[key] === 'object' &&
          weakData[key] !== null
        ) {
          mergeObjects(strongData[key], weakData[key], updateData[key]);
        } else {
          updateData[key] = weakData[key];
        }
      } else {
        updateData[key] = strongData[key];
      }
    } else {
      updateData[key] = weakData[key];
    }
  });
};

export default mergeObjects;
