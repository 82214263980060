import { DeviceItems } from '../../generated/graphql';
import { DeviceResolver } from './types';
import getLocalDeviceResolverByPlatform from './shared/utils/getLocalDeviceResolverByPlatform';
import { BaseResolverParamsType } from '../shared/types';
import { Platform } from '../../enums';
import AbstractLocalDevicesResolver from './shared/AbstractLocalDevicesResolver';
import mergeObjects from './shared/utils/mergeObjects';

/**
 * This function is intended to resolve devices locally based on the provided platform.
 */
type DevicesLocalResolverParams = BaseResolverParamsType & {
  mock?: boolean;
  platform: Platform;
  additionalLocalDevicesResolvers?: AbstractLocalDevicesResolver[];
};

const devicesLocalResolver = async ({
  rootValue,
  args,
  context,
  info,
  mock,
  platform,
  additionalLocalDevicesResolvers
}: DevicesLocalResolverParams): Promise<DeviceItems> => {
  // 1. Retrieve the device resolver specific to the provided platform.
  const _deviceLocalResolver = await getLocalDeviceResolverByPlatform(platform);
  // 2. Get the local devices.
  const localDevices: DeviceResolver[] = await _deviceLocalResolver(
    rootValue,
    args,
    context,
    info,
    mock,
    additionalLocalDevicesResolvers
  );

  // 3. The response object.
  const response: DeviceItems = {
    items: localDevices,
    __typename: 'DeviceItems',
    totalCount: localDevices.length
  };

  // TODO: From here until the end should be improved
  if (!rootValue?.devices) {
    rootValue.devices = { items: [], __typename: 'DeviceItems', totalCount: 0 };
  }

  const cloudDevices = rootValue?.devices?.items || [];

  localDevices.forEach((localDevice) => {
    const correspondingCloudDevice = cloudDevices?.find?.(
      (d) => !!d.deviceId && d.deviceId === localDevice.deviceId
    );
    if (correspondingCloudDevice) {
      // Merge data that doesn't exist on params.data
      mergeObjects(
        localDevice,
        correspondingCloudDevice,
        correspondingCloudDevice
      );
    } else {
      // Create a new object inside params.data
      cloudDevices.push(localDevice);
    }
  });

  if (!isNaN(rootValue?.devices?.totalCount)) {
    rootValue.devices.totalCount = cloudDevices.length;
  }
  // const cloudDevices = rootValue?.devices?.items || [];

  // cloudDevices.forEach((device) => {
  //   const existingDevice = params.data.find(
  //     (d) => d.deviceId === device.deviceId
  //   );
  //   if (existingDevice) {
  //     // Merge data that doesn't exist on params.data
  //     mergeObjects(existingDevice, device);
  //   } else {
  //     // Create a new object inside params.data
  //     params.data.push(device);
  //   }
  // });

  return response;
};

export default devicesLocalResolver;
