export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
export type MakeEmpty<
  T extends { [key: string]: unknown },
  K extends keyof T
> = { [_ in K]?: never };
export type Incremental<T> =
  | T
  | {
      [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never;
    };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
};

export type AccessoryDevice = Device & {
  __typename?: 'AccessoryDevice';
  deviceOwnership?: Maybe<Account>;
  deviceId?: Maybe<Scalars['String']['output']>;
  identity?: Maybe<DeviceIdentity>;
  images?: Maybe<Array<Maybe<DeviceImage>>>;
  lastUpdatedAt?: Maybe<Scalars['String']['output']>;
  type?: Maybe<DeviceType>;
};

export type Account = {
  __typename?: 'Account';
  resourceId?: Maybe<Scalars['String']['output']>;
};

export type Device = {
  deviceOwnership?: Maybe<Account>;
  deviceId?: Maybe<Scalars['String']['output']>;
  identity?: Maybe<DeviceIdentity>;
  images?: Maybe<Array<Maybe<DeviceImage>>>;
  lastUpdatedAt?: Maybe<Scalars['String']['output']>;
  type?: Maybe<DeviceType>;
};

export type DeviceIdentity = {
  __typename?: 'DeviceIdentity';
  bizModel?: Maybe<Scalars['String']['output']>;
  deviceSegment?: Maybe<Scalars['String']['output']>;
  deviceUuid?: Maybe<Scalars['String']['output']>;
  friendlyName?: Maybe<Scalars['String']['output']>;
  lastUpdatedAt?: Maybe<Scalars['String']['output']>;
  location?: Maybe<Scalars['String']['output']>;
  makeAndModel?: Maybe<DeviceMakeAndModel>;
  serialNumber?: Maybe<Scalars['String']['output']>;
};

export type DeviceImage = {
  __typename?: 'DeviceImage';
  url?: Maybe<Scalars['String']['output']>;
};

export type DeviceItems = {
  __typename?: 'DeviceItems';
  items?: Maybe<Array<Maybe<Device>>>;
  totalCount?: Maybe<Scalars['Int']['output']>;
};

export type DeviceMakeAndModel = {
  __typename?: 'DeviceMakeAndModel';
  name?: Maybe<Scalars['String']['output']>;
  number?: Maybe<Scalars['String']['output']>;
  series?: Maybe<Scalars['String']['output']>;
};

export enum DeviceType {
  Keyboard = 'KEYBOARD',
  Mouse = 'MOUSE',
  Pc = 'PC',
  Pen = 'PEN',
  Printer = 'PRINTER'
}

export type PcDevice = Device & {
  __typename?: 'PCDevice';
  deviceOwnership?: Maybe<Account>;
  deviceId?: Maybe<Scalars['String']['output']>;
  identity?: Maybe<DeviceIdentity>;
  images?: Maybe<Array<Maybe<DeviceImage>>>;
  lastUpdatedAt?: Maybe<Scalars['String']['output']>;
  type?: Maybe<DeviceType>;
};

export type PrinterDevice = Device & {
  __typename?: 'PrinterDevice';
  deviceOwnership?: Maybe<Account>;
  deviceId?: Maybe<Scalars['String']['output']>;
  identity?: Maybe<DeviceIdentity>;
  images?: Maybe<Array<Maybe<DeviceImage>>>;
  lastUpdatedAt?: Maybe<Scalars['String']['output']>;
  type?: Maybe<DeviceType>;
};

export type Query = {
  __typename?: 'Query';
  devicesLocal?: Maybe<DeviceItems>;
};

export type QueryDevicesLocalArgs = {
  deviceId?: InputMaybe<Scalars['String']['input']>;
  deviceType?: InputMaybe<Array<InputMaybe<DeviceType>>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};
