import { setContext } from '@apollo/client/link/context';

// This can't be extended from ApolloLink because it uses async method.
const AuthLink = (authTokenCallback) => {
  if (authTokenCallback === undefined)
    throw new Error('authTokenCallback is required');

  const authLink = setContext(async (_, { headers }) => {
    const token = await authTokenCallback();
    return {
      headers: {
        ...headers,
        authorization: token ? `Bearer ${token}` : ''
      }
    };
  });
  return authLink;
};

export default AuthLink;
