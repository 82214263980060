import { Device } from '../../../generated/graphql';
import Resolver from '../../shared/Resolver';
import { DeviceResolver } from '../types';
import { DeviceResolverParamsType } from './types';
import mergeObjects from '../shared/utils/mergeObjects';

export default abstract class AbstractLocalDevicesResolver implements Resolver {
  private nextResolver: AbstractLocalDevicesResolver;

  public setNext(
    resolver: AbstractLocalDevicesResolver
  ): AbstractLocalDevicesResolver {
    this.nextResolver = resolver;
    return resolver;
  }

  public async resolve(params: DeviceResolverParamsType): Promise<Device[]> {
    if (this.nextResolver) {
      return this.nextResolver.resolve(params);
    }

    return params?.data;
  }

  public async normalize(
    devices: DeviceResolver[],
    params: DeviceResolverParamsType
  ): Promise<void> {
    if (!params?.data) {
      params.data = [];
    }

    devices.forEach((device) => {
      const existingDevice = params?.data?.find?.(
        (d) => !!d.deviceId && d.deviceId === device.deviceId
      );
      if (existingDevice) {
        // Merge data that doesn't exist on params.data
        mergeObjects(existingDevice, device, existingDevice);
      } else {
        // Create a new object inside params.data
        params.data.push(device);
      }
    });
  }
}
